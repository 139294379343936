import React, {
  useEffect,
  useState,
} from 'react'
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha"
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Auth } from 'aws-amplify';
import { FloatingWhatsApp } from 'react-floating-whatsapp'


import './OnboardingForm.css'

import SuccessIcon from '../../assets/svg/success_icon.svg'
import VencoLogo from '../../assets/svg/venco_logo.svg'
import VencoIcon from '../../assets/svg/venco_icon.svg'
import Pin from '../../assets/svg/pin.svg'
import CloseIcon from '../../assets/svg/close-round.svg'
import { confirm_community_onboarding, get_countries, get_location, request_community_onboarding, request_otp, signIn, verify_otp } from '../../api/auth';

const sleep = (milliseconds) => {
	return new Promise(resolve => setTimeout(resolve, milliseconds))
}


export default function OnboardingForm(props) {
  const [activeSection, setActiveSection] = useState("form") // message
  const [modalSection, setModalSection] = useState(0)  
  const [inputValues, setInputValues] = useState({
    // community info
    communityName: '',
    communityID: '',
    noOfProperties: '',
    country: '',
    state: '',
    streetAddress: '',
    communityDomainName: '',
    isForExistingGroup: false,

    // personal info
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    customRole: '',
    userRole: '',
    userId: '',

    // meters
    electricMeters: false,
    waterMeters: false,
    gasMeters: false,
    noMeters: false,

    communityGroup: null,
    otp: '',
  })
  const [errMsg, setErrMsg] = useState({
    // community info
    communityName: '',
    communityID: '',
    noOfProperties: '',
    country: '',
    state: '',
    streetAddress: '',
    communityDomainName: '',


    // personal info
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    customRole: '',
    userRole: '',
    userId: '',

    // meters
    electricMeters: '',
    waterMeters: '',
    gasMeters: '',
    noMeters: '',

    otp: '',
    communityGroup: '',
  })
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubTitle, setModalSubTitle] = useState('')
  const [tempId, setTempId] = useState('')
  const [successMsg, setSuccessMsg] = useState('Your request has be received, but Venco is currently available in United States, you’d be the first to know as soon as we launch there.')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [isPersonalInfoEditable, setIsPersonalInfoEditable] = useState(true)
  const [useExistingSite, setUseExistingSite] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [placeSuggestions, setPlaceSuggestions] = useState([])
  const [showplaceSuggestions, setShowplaceSuggestions] = useState(false)
  const [countries, setCountries] = useState([])

  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false)

  const recaptchaRef =  React.createRef()

  useEffect(() => {
    getCountries()
  }, [])

  const handleInputChange = (event) => {
    const target = event.target;
    const value = (target.type === 'checkbox' || target.type == "radio")? target.checked : target.value;
    const name = target.name;

    // console.log('value', value);
    
    setInputValues(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === "isForExistingGroup" && value == true) {
      // console.log('userInfo?.community_groups?', userInfo?.community_groups[3].name);
      setModalSection(0)
      setModalIsOpen(true)
    }
  }

  const handleStreetChange = async (event) => {
    const target = event.target;
    const value = target.value;

    setInputValues(prevState => ({
      ...prevState,
      streetAddress: value
    }));
    if (value.length > 3 && (value.length === 3 || value.length % 2 === 0)) {
      await getLocation(value)
    }
  }

  const onRecaptchaChange = (value) => {
    setRecaptchaVerified(value)
  }

  const getLocation = async (query) => {
    try {
      var params = {
        "use_default": false,
        "input_text": query,
        "language":"EN"
      }

      // console.log('getLocation params', params);

      const response = await get_location(params)

      if (response.ok) {
        const res = await response.json()
        
        console.log('res', res);
        if (Boolean(res)){
          setPlaceSuggestions(res)
          setShowplaceSuggestions(true)
        }
        
      } else {
        setPlaceSuggestions([])
        setShowplaceSuggestions(false)
      }
    } catch (error) {
      setPlaceSuggestions([])
      setShowplaceSuggestions(false)
    }
  }

  const statesInNigeria = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara"
  ];

  const statesInKenya = [
    "Baringo",
    "Bomet",
    "Bungoma",
    "Busia",
    "Elgeyo-Marakwet",
    "Embu",
    "Garissa",
    "Homa Bay",
    "Isiolo",
    "Kajiado",
    "Kakamega",
    "Kericho",
    "Kiambu",
    "Kilifi",
    "Kirinyaga",
    "Kisii",
    "Kisumu",
    "Kitui",
    "Kwale",
    "Laikipia",
    "Lamu",
    "Machakos",
    "Makueni",
    "Mandera",
    "Marsabit",
    "Meru",
    "Migori",
    "Mombasa",
    "Murang'a",
    "Nairobi",
    "Nakuru",
    "Nandi",
    "Narok",
    "Nyamira",
    "Nyandarua",
    "Nyeri",
    "Samburu",
    "Siaya",
    "Taita-Taveta",
    "Tana River",
    "Tharaka-Nithi",
    "Trans-Nzoia",
    "Turkana",
    "Uasin Gishu",
    "Vihiga",
    "Wajir",
    "West Pokot"
  ];

  function isValidEmail(value) {
    // Use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }
  
  function isValidPhoneNumber(value) {
    // Use a regular expression to validate the phone number format
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  }

  function sortByActiveStatus(a, b) {
    if (a.active === b.active) {
      // If both items have the same active status, maintain the original order
      return 0;
    } else if (a.active === 1) {
      // Move items with active: 1 to the top
      return -1;
    } else {
      // Move items with active: 0 to the bottom
      return 1;
    }
  }

  const getCountries = async () => {
    try {
      await sleep(2000)
      const res = await get_countries()
      // console.log("res status", res.status)
      if (res.ok) {
        const {message} = await res.json()
        const data = message.data
        data.sort(sortByActiveStatus);
        setCountries(data)
      } else {
        setCountries([
          {
            "country_name": "Ghana",
            "country_code": "gh",
            "currency": "GHS",
            "active": 1,
            "emergency_numbers": [
              {
                "emergency_number_type": "Police",
                "emergency_number": "191"
              },
              {
                "emergency_number_type": "Fire",
                "emergency_number": "192"
              },
              {
                "emergency_number_type": "Medical",
                "emergency_number": "193"
              }
            ]
          },
          {
            "country_name": "Kenya",
            "country_code": "ke",
            "currency": "KES",
            "active": 1,
            "emergency_numbers": [
              {
                "emergency_number_type": "General",
                "emergency_number": "112"
              }
            ]
          },
          {
            "country_name": "Nigeria",
            "country_code": "ng",
            "currency": "NGN",
            "active": 1,
            "emergency_numbers": [
              {
                "emergency_number_type": "General",
                "emergency_number": "112"
              }
            ]
          }
        ])
      }
    } catch (error) {
      
    }
  }

  const verifyUser = async () => {
    try {
      setIsSubmitting(true)
      var isEmail = false
      var params = {}
      if (isValidEmail(inputValues.userId)) {
        // Handle email input
        params['email'] = inputValues.userId
        setErrMsg(prevState => ({
          ...prevState,
          userId: ""
        }))
        isEmail= true
      } else if (isValidPhoneNumber(inputValues.userId)) {
        // Handle phone number input
        params['phoneNumber'] = inputValues.userId
        setErrMsg(prevState => ({
          ...prevState,
          userId: ""
        }))
        isEmail = false
      } else {
        // Handle invalid input
        setErrMsg(prevState => ({
          ...prevState,
          userId: "Kindly enter a valid email or phone number"
        }))
        setIsSubmitting(false)
        // console.log('verifyUser failed');
      }

      const response = await signIn(params)
      // console.log('status', response.status)
      if (response.ok) {
        var res = await response.json()
        if (res.enrolled) {
          setTempId(res.id)
          var requestOtpRes = await requestOtp(res.id)

          if (requestOtpRes.status === 'success') {
            setModalTitle('Please verify OTP')
            setModalSubTitle('Please enter the OTP code that was sent to ')
            setModalSection(2)
            setIsSubmitting(false)
            setErrMsg(prevState => ({
              ...prevState,
              userId: '',
            }))
          } else {
            setErrMsg(prevState => ({
              ...prevState,
              userId: requestOtpRes?.message,
            }))
          }

          
        } else {
          setErrMsg(prevState => ({
            ...prevState,
            userId: isEmail ? "Email is not registered to any account on the VENCO platform" : "Phone number is not registered to any account on the VENCO platform"
          }))
          setIsSubmitting(false)
        }
      }else {
        if (response.status === 500) {
          setErrMsg(prevState => ({
            ...prevState,
            userId: 'Internal server error',
          }))
          setIsSubmitting(false)
        } else {
          var {message, code} = await response.json()
          setErrMsg(prevState => ({
            ...prevState,
            userId: message ? `${message} code - ${code}` : ("Oops... Something went wrong. code " + response.status)
          }))
          setIsSubmitting(false)
        }
        
      }
    } catch (error) {
      setErrMsg(prevState => ({
        ...prevState,
        userId: "Oops... Something went wrong. \nError: " + error.message
      }))
      setIsSubmitting(false)
    }
  }

  const requestOtp = async id => {    
    try {
      setIsSubmitting(true)
      var params = {
        id,
      }

      
      var response = await request_otp(params)
      var res = await response.json()

      if (response.ok) {
        setInputValues(prevState => ({
          ...prevState,
          otp: ''
        }));
        return {
          status: 'success'
        }
      } else {
        if (response.status === 500) {
          return {
            status: 'error',
            message: 'Internal server error'
          }
        } else {  
          return {
            status: 'error',
            message: res.message
          }  
        }
      }        
     
            
    } catch (error) {

      return {
        status: 'error',
        message: 'Something went wrong. Error: ' + error.message
      } 
    }
  };

  const requestCommunityOnboarding = async (event) => {   
    event.preventDefault(); // Prevent page refresh 
    try {
      if(!isRecaptchaVerified) {
        toast.error('Error submitting, please verify reCAPTCHA and try again', {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        return false
      }
      setIsSubmitting(true)
      

      var params = {
        email: inputValues.email,
        phone_number: inputValues.phoneNo,
        community_name: inputValues.communityName,        
        country: inputValues.country,
        address: inputValues.streetAddress,
        role: inputValues.userRole,
      }

      // console.log('params', params);

      if (inputValues.communityGroup) {
        params["host_url"] = inputValues.communityGroup
      }

      
      var response = await request_community_onboarding(params)
      var res = await response.json()

      // console.log('response status', response.status);
      // console.log('res', res);

      if (response.ok) {
        setTempId(res.tmp_id)
        // console.log("res.tmp_id", res.tmp_id);
        setInputValues(prevState => ({
          ...prevState,
          otp: ''
        }));        
        setErrMsg(prevState => ({
          ...prevState,
          otp: ''
        }));        
        setOtpModalIsOpen(true)
        
        // return {
        //   status: 'success'
        // }
      } else {
        if (response.status === 500) {
          toast.error(res.message || 'Internal server error', {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        } else {  
          toast.error(res.message || "Something went wrong", {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }
        setIsSubmitting(false)
      }        
     
            
    } catch (error) {
      toast.error('Something went wrong. Error: ' + error.message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
      setIsSubmitting(false)
    }
    return false
  };

  const submitCommunityOnboardingRequest = async () => {
    try {
      var params ={
        tmp_id: tempId,
        otp: inputValues.otp,
        first_name: inputValues.firstName,
        last_name: inputValues.lastName,
        email: inputValues.email,
        phone_number: inputValues.phoneNo,
        community_name: inputValues.communityName,
        country: inputValues.country,
        address: inputValues.streetAddress,
        role: inputValues.userRole,
        // test: 1
      } 

      
     

      if (inputValues.communityGroup) {
        params["host_url"] = inputValues.communityGroup
      }

      // console.log(' confirm_community_onboarding params', params);

      const res = await confirm_community_onboarding(params)
      const response = await res.json()

      // console.log('res status', res.status)
      // console.log('response', response)
      if (res.ok) {
        setSuccessMsg(response?.message || "Success")
        closeVerifyUserModal()
        setActiveSection("message")
        
        
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        setIsSubmitting(false)
      }
    } catch (error) {
      toast.error('Something went wrong. Error: ' + error.message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
      setIsSubmitting(false)
    }
  }

  const verifyOtp = async () => {
    try {
      setIsSubmitting(true)
      var isEmail = false
      var params = {
        id: tempId,
        otp: inputValues.otp,
      }
      if (isValidEmail(inputValues.userId)) {
        // Handle email input
        params['email'] = inputValues.userId
        setErrMsg(prevState => ({
          ...prevState,
          otp: ""
        }))
        isEmail= true
      } else if (isValidPhoneNumber(inputValues.userId)) {
        // Handle phone number input
        params['phoneNumber'] = inputValues.userId
        setErrMsg(prevState => ({
          ...prevState,
          otp: ""
        }))
        isEmail = false
      }

      var response = await verify_otp(params)

      if (response.ok) {
        const res = await response.json()
        const { apiKey, secretKey, ...userInfo } = res.data

        // console.log('userInfo', userInfo);
        if(!Boolean(userInfo?.community_groups?.length)) {
          setModalTitle('No existing site')
          setModalSubTitle('The ' + (isEmail ? " email " : " phone number ") + "you provided isn't associated to any community")
          setModalSection(4)
        } else {
          setUserInfo(userInfo)
          setModalTitle('Select existing site')
          setModalSubTitle('Now please select the community group or site you would like to add this new community to.')
          setModalSection(3)
        }
        
        setIsSubmitting(false)
        setErrMsg(prevState => ({
          ...prevState,
          otp: '',
        }))
      } else {
        const res = await response.json()
        setErrMsg(prevState => ({
          ...prevState,
          otp: res.message,
        }))
        setIsSubmitting(false)
      }

    } catch (error) {
      setErrMsg(prevState => ({
        ...prevState,
        userId: "Oops... Something went wrong. \nError: " + error.message
      }))
      setIsSubmitting(false)
    }
  }

  const selectExistingSite = () => {
    // // console.log('inputValues.communityGroup', inputValues.communityGroup.name);
    if (inputValues.communityGroup) {
      setModalIsOpen(false)
      setUseExistingSite(true)
      setIsPersonalInfoEditable(false)
      setInputValues(prevState => ({
        ...prevState,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        email: userInfo?.id,
        phoneNo: userInfo?.phone,
        // communityDomainName: prevState.communityGroup.estates[0].host,
      }));

    } else {
      setErrMsg(prevState => ({
        ...prevState,
        communityGroup: 'Please select the community group/site you would like to add the new site to',
      }))
    }
    
  }
 
  const toggleModal = (value) => {
    if (value) {
      setModalTitle('Please Confirm')
      setModalSubTitle('Do you or your organization have an existing VENCO site and would like for this new community to be hosted on the same site as the previous one?')
    } else {
      setInputValues(prevState => ({
        ...prevState,
        isForExistingGroup: false,
      }))
    }

    setModalIsOpen(value);
    
  }

  const selectPlace = (item) => {
    setInputValues(prevState => ({
      ...prevState,
      streetAddress: item.description,
    }));
    setPlaceSuggestions([])
    setShowplaceSuggestions(false)
  }

  const closeVerifyUserModal = () => {
    setIsSubmitting(false)
    setOtpModalIsOpen(false)
  }
  
  
  const customStyles = {
    content: {
      // top: '50%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      zIndex: 99999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(2px)',
    }
  };

  return (
    <div>
      <div className='container'>
        {
          activeSection === "form" && (
            <>
              <div className='header'>
                <a href='https://venco.africa/'>
                  <img 
                    src={VencoLogo}
                    className='logo'
                  />
                </a>          
                <div>
                  <p className='header-title'>
                    Let's get your community started!
                  </p>
                  <p className='header-desc'>
                    Please fill out the form below to provide basic information about your community
                  </p>
                </div>      
              </div>
              <div className='section-0'>
                <form className='onboarding-form' onSubmit={requestCommunityOnboarding}>
                  <div
                    className='input-field-group'
                  > 
                    <div>
                      <span>
                      <p className='input-label'>Community Name</p>
                      <div className="input-field-box">
                        <input 
                          type='text' 
                          value={inputValues.communityName} 
                          name='communityName'
                          placeholder='Community Name'
                          onChange={handleInputChange} 
                          // autoComplete='name'
                          required
                          // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                          className="input-field"                
                        />
                      </div>
                      
                    </span>
                    <div
                      className='is-existing-group'
                    >
                      <label className='input-label'>
                        <input
                          type="checkbox"
                          value={inputValues.isForExistingGroup}
                          onChange={handleInputChange}
                          name="isForExistingGroup"
                          checked={inputValues.isForExistingGroup}
                        />
                        Is this new community managed as part of an existing group of communities on the VENCO platform?
                      </label>
                    </div>
                    </div>
                    <span className='input-span'>
                      <p className='input-label'>Select the role that best describes you</p>      
                      <div className="input-field-box">
                        <select 
                          name="userRole" 
                          id="userRole"
                          className="input-field"
                          value={inputValues.userRole}
                          onChange={handleInputChange}
                          // disabled={!isPersonalInfoEditable}
                        >
                          <option value="" defaultValue disabled>Select your role</option>
                          <option value="Facilities Manager">Facilities Manager</option>
                          <option value="Property Manager">Property Manager</option>
                          <option value="Resident">Resident</option>
                          <option value="Community Association ExCo">Community Association ExCo</option>
                          <option value="Property Developer">Property Developer</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      
                    </span>
                  </div>
                 
                  <div
                    className='input-field-group'
                  > 
                    <span className='input-span'>
                      <p className='input-label'>First Name</p>
                      <div className="input-field-box">
                        <input 
                          type='text' 
                          value={inputValues.firstName} 
                          name='firstName'
                          placeholder='First Name'
                          onChange={handleInputChange} 
                          autoComplete='name'
                          required
                          disabled={!isPersonalInfoEditable}
                          // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                          className="input-field"                
                        />
                      </div>              
                    </span>
                    <span className='input-span'>
                      <p className='input-label'>Last Name</p>
                      <div className="input-field-box">
                        <input 
                          type='text' 
                          value={inputValues.lastName} 
                          name='lastName'
                          placeholder='Last Name'
                          onChange={handleInputChange} 
                          autoComplete='name'
                          required
                          disabled={!isPersonalInfoEditable}
                          // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                          className="input-field"                
                        />
                      </div>
                    </span>
                  </div>
                  
                  <div
                    className='input-field-group'
                  > 
                    <span className='input-span'>
                      <p className='input-label'>Email Address</p>
                      <div className="input-field-box">
                        <input 
                          type='text' 
                          value={inputValues.email} 
                          name='email'
                          placeholder='Email Address'
                          onChange={handleInputChange} 
                          autoComplete='email'
                          required
                          disabled={!isPersonalInfoEditable}
                          // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                          className="input-field"                
                        />
                      </div>
                    </span>
                    <span className='input-span'>
                      <p className='input-label'>Phone Number</p>
                      <div className="input-field-box">
                        <input 
                          type='text' 
                          value={inputValues.phoneNo} 
                          name='phoneNo'
                          placeholder='Tel eg. +234 706 123 4567 890'
                          onChange={handleInputChange} 
                          autoComplete='tel'
                          required
                          disabled={!isPersonalInfoEditable}
                          // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                          className="input-field"                
                        />
                      </div>
                  </span>
                  </div>
                  
                  <div
                    className='input-field-group'
                  > 
                    <div>
                      <div className='input-span'>
                        <p className='input-label'>Street Address</p>
                        <div className="input-field-box">
                          <input 
                            type='text' 
                            value={inputValues.streetAddress} 
                            name='streetAddress'
                            placeholder='Street Address'
                            onChange={handleStreetChange} 
                            // autoComplete='name'
                            required
                            // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                            className="input-field-2"                
                          />                     
                        </div>
                      </div>  
                        {
                          showplaceSuggestions ? (
                            <div className="street-suggestions">
                              <div className="street-suggestions-contents">                              
                                { 
                                  placeSuggestions.map((item, index) => {
                                    return (
                                      <div 
                                        className='suggestion-item'
                                        onClick={() => selectPlace(item)}
                                      >
                                        <img 
                                          src={Pin} 
                                          alt="Right arrow" 
                                          style={{
                                            width: 14,
                                          }}
                                        />
                                        <p>{item.description}</p>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          ) : null
                        }
                      </div>
                    <span className='input-span'>
                      <p className='input-label'>Country</p>      
                      <div className="input-field-box">
                      <select 
                        name="country" 
                        id="country"
                        className="input-field"
                        value={inputValues.country}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" defaultValue disabled>Select a country</option>
                        {
                          countries.map((item, index) => (
                            <option 
                              value={item.country_name}
                              key={index}
                            >
                              {item.country_name}
                            </option>
                          ))
                        }
                        {/* <option value="Nigeria">Nigeria</option>
                        <option value="Kenya">Kenya</option> */}
                      </select>
                      </div>
                    </span>
                  </div>
                  
                  
                  {/* <span className='input-span'>
                    <p className='input-label'>State</p>     
                    <div className="input-field-box">
                      <select 
                        name="state" 
                        id="state"
                        className="input-field"
                        value={inputValues.state}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" defaultValue disabled>Select a state</option>
                        {
                          (inputValues.country === 'Nigeria' ? (
                            statesInNigeria.map((item, index) => {
                              return (  
                                <option key={index} value={item}>{item}</option>
                              )
                            })
                          ) : null)
                        }
                        {
                          (inputValues.country === 'Kenya' ? (
                            statesInKenya.map((item, index) => {
                              return (  
                                <option key={index} value={item}>{item}</option>
                              )
                            })
                          ) : null)
                        }
                        
                      </select>
                    </div> 
                  </span> */}
                         
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 25,
                    }}
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      tabindex={1}
                      sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                      onChange={onRecaptchaChange}
                    />
                  </span>   
                  <button 
                    className='primary-btn'
                    style={{
                      marginTop: "25px",
                    }}
                    type='submit'              
                  >
                    {isSubmitting ? 'Loading...' : 'Submit'}
                    {/* <img 
                      src={RightArrow} 
                      alt="Right arrow" 
                    /> */}
                  </button>
                  <div className='terms-section'>
                    <p>
                      By clicking the “Submit” button, you agree to VENCO's <a href='https://venco.africa/terms-and-conditions/'>terms of service</a> and  <a href='https://venco.africa/privacy-policy/'>privacy policy</a>
                    </p>
                  </div>
                  
                 
                </form>
                
              </div>
            </>
          )
        }
        {
          activeSection === "message" && (
            <>
              <div className='header'>
                <a href='https://venco.africa/'>
                  <img 
                    src={VencoLogo}
                    className='logo'
                  />
                </a>                  
              </div>
              <div className='section-1'>
                <img 
                  src={SuccessIcon}
                  className='success-icon'
                />
                <p>
                  {successMsg}
                </p>
                <a href='https://venco.africa/'>
                  Back To Home
                </a>
              </div>
            </>
          )
        }
        {/* <div className='fab'>
          <Fab color="success" aria-label="Support" href='https://api.whatsapp.com/send?phone=2349131055071' target="_blank">
            <WhatsAppIcon />
          </Fab>
        </div> */}
        <FloatingWhatsApp 
          phoneNumber="2349131055071"
          accountName="Venco Support"
          statusMessage="Online"
          avatar={VencoIcon}
        />
        
        
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal(false)}
        style={customStyles}
        // contentLabel="Vessel Modal"
        // overlayClassName="modal-overlay"
        className="vessel-modal" 
      >
        <div
          className='modal-contents'
        >
          {
            (modalSection === 0) ? (
              <>
                <div
                  className='close-btn-group'
                >
                  <img 
                    src={CloseIcon}
                    alt="Close Button"
                    onClick={() => toggleModal(false)}
                  />
                </div>
                <p
                  className='modal-title'
                >
                  Please Confirm
                </p>
                <p
                  className='modal-sub-title'
                >
                  Do you or your organisation have an existing VENCO site and would like for this new community to be hosted on the same site as the previous one?
                </p>                
                <div
                  className='modal-btn-group'
                >
                  <button
                    onClick={() => toggleModal(false)}                    
                    className='modal-secondary-btn'
                  >
                    No, Take me back
                  </button>
                  <button
                    className='modal-primary-btn'
                    onClick={() => {                      
                      setModalTitle('Okay, Let’s verify who you are')
                      setModalSubTitle('Please enter your email or phone number current being used on the VENC0 platform ')
                      setModalSection(1)
                    }}
                  >
                    Yes, Let's proceed
                  </button>
                </div>
              </>
            ) : null
          }
          {
            (modalSection === 1) ? (
              <>
                <div
                  className='close-btn-group'
                >
                  <img 
                    src={CloseIcon}
                    alt="Close Button"
                    onClick={() => toggleModal(false)}
                  />
                </div>
                <p
                  className='modal-title'
                >
                  {modalTitle}
                </p>
                <p
                  className='modal-sub-title'
                >
                  {modalSubTitle}
                </p>
                <div 
                  style={{
                    marginLeft: '20px',
                    marginTop: '20px',  
                  }}  
                  className={Boolean(errMsg.userId.length) ? "input-field-box-err" : "input-field-box"}  
                >
                  <input 
                    type='text' 
                    value={inputValues.userId} 
                    name='userId'
                    placeholder='Email or Tel eg. +234 706 123 4567 890'
                    onChange={handleInputChange} 
                    autoComplete='text'
                    required
                    // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                    className='input-field'
                         
                  />
                </div>
                
                {
                  Boolean(errMsg.userId.length) ? (
                    <p
                      className='input-field-err-msg'
                    >
                      {errMsg.userId}
                    </p>
                  ) : null
                }
                <div
                  className='modal-btn-group-1'
                >
                  <button
                    className='modal-primary-btn'
                    onClick={verifyUser}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : null
          }
          {
            (modalSection === 2) ? (
              <>
                <div
                  className='close-btn-group'
                >
                  <img 
                    src={CloseIcon}
                    alt="Close Button"
                    onClick={() => toggleModal(false)}
                  />
                </div>
                <p
                  className='modal-title'
                >
                  {modalTitle}
                </p>
                <p
                  className='modal-sub-title'
                >
                  {modalSubTitle} <span style={{color: "blue"}}>{inputValues.userId}</span>
                </p>
                <div 
                  style={{
                    marginLeft: '20px',
                    marginTop: '20px',  
                  }}  
                  className={Boolean(errMsg.userId.length) ? "input-field-box-err" : "input-field-box"}  
                >
                <input 
                  type='number' 
                  value={inputValues.otp} 
                  name='otp'
                  placeholder=' Type your OTP code'
                  onChange={handleInputChange} 
                  autoComplete='text'
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className='input-field'
                       
                />
                </div>
                {
                  Boolean(errMsg.otp.length) ? (
                    <p
                      className='input-field-err-msg'
                    >
                      {errMsg.otp}
                    </p>
                  ) : null
                }
                <div
                  className='modal-btn-group'
                >
                  <button                 
                    className='modal-secondary-btn'
                    onClick={() => {                      
                      setModalTitle('Okay, Let’s verify who you are')
                      setModalSubTitle('Please enter your email or phone number current being used on the VENC0 platform ')
                      setModalSection(1)
                    }}
                  >
                    Back
                  </button>
                  <button
                    className='modal-primary-btn'
                    onClick={verifyOtp}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : null
          }
          {
            (modalSection === 3) ? (
              <>
                <div
                  className='close-btn-group'
                >
                  <img 
                    src={CloseIcon}
                    alt="Close Button"
                    onClick={() => toggleModal(false)}
                  />
                </div>
                <p
                  className='modal-title'
                >
                  {modalTitle}
                </p>
                <p
                  className='modal-sub-title'
                >
                  {modalSubTitle}
                </p>
                <div 
                  style={{
                    marginLeft: '20px',
                    marginTop: '20px',  
                  }}  
                  className={Boolean(errMsg.userId.length) ? "input-field-box-err" : "input-field-box"}  
                >
                <select 
                  name="communityGroup" 
                  id="communityGroup"
                  className='input-field'
                  value={inputValues.communityGroup}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" defaultValue>-- Select a Community Group/Site --</option>
                  {
                    (Boolean(userInfo?.community_groups?.length) ? (
                      userInfo?.community_groups.map((item, index) => {
                        return (  
                          <option key={index} value={item.estates[0].host}>{item.estates[0].host}</option>
                        )
                      })
                    ) : null)
                  }
                  
                </select>
                </div>
                {
                  Boolean(errMsg.communityGroup.length) ? (
                    <p
                      className='input-field-err-msg'
                    >
                      {errMsg.communityGroup}
                    </p>
                  ) : null
                }
                <div
                  className='modal-btn-group-1'
                >
                  <button
                    className='modal-primary-btn'
                    onClick={selectExistingSite}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : null
          }
          {
            (modalSection === 4) ? (
              <>
                <div
                  className='close-btn-group'
                >
                  <img 
                    src={CloseIcon}
                    alt="Close Button"
                    onClick={() => toggleModal(false)}
                  />
                </div>
                <p
                  className='modal-title'
                >
                  {modalTitle}
                </p>
                <p
                  className='modal-sub-title'
                >
                  {modalSubTitle}
                </p>
                {
                  Boolean(errMsg.communityGroup.length) ? (
                    <p
                      className='input-field-err-msg'
                    >
                      {errMsg.communityGroup}
                    </p>
                  ) : null
                }
                <div
                  className='modal-btn-group-1'
                >
                  <button
                    className='modal-primary-btn'
                    onClick={() => toggleModal(false)}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : null
          }
          
        </div>
      </Modal>
      <Modal
        isOpen={otpModalIsOpen}
        onRequestClose={() => closeVerifyUserModal()}
        style={customStyles}
        // contentLabel="Vessel Modal"
        // overlayClassName="modal-overlay"
        className="vessel-modal" 
      >
        <div
          className='modal-contents'
        >
          <div
            className='close-btn-group'
          >
            <img 
              src={CloseIcon}
              alt="Close Button"
              onClick={() => closeVerifyUserModal()}
            />
          </div>
          <p
            className='modal-title'
          >
            Please verify OTP
          </p>
          <p
            className='modal-sub-title'
          >
            Please enter the OTP code that was sent to your email and/or phone number
          </p>
          <div 
            style={{
              marginLeft: '20px',
              marginTop: '20px',  
            }}  
            className={Boolean(errMsg.userId.length) ? "input-field-box-err" : "input-field-box"}  
          >
          <input 
            type='number' 
            value={inputValues.otp} 
            name='otp'
            placeholder=' Type your OTP code'
            onChange={handleInputChange} 
            autoComplete='text'
            required
            // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
            className='input-field'
                  
          />
          </div>
          {
            Boolean(errMsg.otp.length) ? (
              <p
                className='input-field-err-msg'
              >
                {errMsg.otp}
              </p>
            ) : null
          }
          <div
            className='modal-btn-group-1'
          >
            <button
              className='modal-primary-btn'
              onClick={submitCommunityOnboardingRequest}
            >
              Submit
            </button>
          </div>
          
        </div>
      </Modal>
    </div>
  )
}